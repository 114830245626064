<template>
  <div class="contact">

    <div class="page-title">
      <div class="content-container">
        <h1 class="title is-4">
          Contact
        </h1>
      </div>
    </div>

    <div class="content-container">

      <h4 class="title is-5 pad-top-20 margin-top-20">Thanks for visiting the CFE Projects & Maintenance website.</h4>
      <div class="subtitle">For all enquires please fill out the the form below.</div>

      <div class="columns margin-top-20">
        <div v-if="sent" class="column sent flex-middle">
          <b-message type="is-success">
            <b>Enquiry Sent!</b>
          </b-message>
        </div>
        <div v-else class="column inputs">
          <div>
            Your Name (required)
            <b-input v-model="name" />
          </div>

          <div>
            Your Email (required)
            <b-input ref="emailInput" type="email" v-model="email" />
          </div>

          <!-- <div>
            Subject
            <b-input type="email" v-model="subject" />
          </div> -->

          <div>
            Your Message
            <b-input type="textarea" v-model="msg" />
          </div>

          <div>
            <vue-recaptcha
              sitekey="6LfDwEQaAAAAAPwwQtogqYN4GlT9qFJl6lEnezWR"
              @verify="verified = true"
              @expired="verified = false"
              @error="verifoed = false">
            </vue-recaptcha>
          </div>

          <button :class="{ 'is-loading': sending }" class="button is-info" v-if="verified" @click="send()">Send</button>

        </div>
        <div class="column">
          <p class="invisible">Contact</p>
          <div class="flex margin-bottom-10">
            <div class="w-40px">
              <fa icon="map-marker-alt" class="margin-right-5" />
            </div>
            <div class="flex-1">
              51 Union Road, North Albury, NSW 2640
            </div>
          </div>

          <div class="flex margin-bottom-10">
            <div class="w-40px">
              <fa icon="phone-alt" class="margin-right-5" />
            </div>
            <div class="flex-1">
              <a href="tel:0260780863" class="phone">02 6078 0863</a>
            </div>
          </div>

          <div class="flex margin-bottom-20">
            <div class="w-40px">
              <fa :icon="['fas', 'envelope']" />
            </div>
            <div class="flex-1">
              <a target="_blank" href="mailto:enquiries@cfep.com.au">enquiries@cfep.com.au</a>
            </div>
          </div>

          <GmapMap
            :center="pos"
            :zoom="13"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: true
            }"
            style="width: 100%; height: 400px"
          >
            <GmapMarker :position="pos" />
          </GmapMap>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import http from 'axios'
import { DialogProgrammatic as Dialog } from 'buefy'
export default {
  components: { VueRecaptcha },
  data () {
    return {
      pos: { lat: -36.054945, lng: 146.950763 },
      name: '',
      email: '',
      msg: '',
      verified: false,
      sending: false,
      sent: false
    }
  },
  methods: {
    async send () {
      if (!this.verified) return

      const validEmail = this.$refs.emailInput.checkHtml5Validity()
      const name = this.name.trim()
      const email = this.email.trim()
      const msg = this.msg.trim()

      console.log(name, email, msg, validEmail)

      const url = 'https://asia-northeast1-cfep-fd3e2.cloudfunctions.net/api/website-contact'
      this.sending = true
      this.sent = false
      try {
        const resp = await http.post(url, { name, email, msg })
        console.log(resp.data)
        if (resp.data && resp.data.status === 'success') {
          this.sent = true
        }
      } catch (error) {
        console.log(error)
      }
      if (this.sent) {
        Dialog.alert({
          type: 'is-info',
          title: 'Sent',
          message: 'Thank you for the enquiry.'
        })
      } else {
        Dialog.alert({
          type: 'is-danger',
          title: 'Error',
          message: 'Could not send enquiry. Please make sure the email address is correct.'
        })
      }

      this.sending = false
    }
  }
}
</script>

<style lang="scss">
.contact {
  padding-bottom: 60px;

  .inputs > div {
    margin-bottom: 30px;
  }

  a {
    color: #0081cc;
  }

  a:hover {
    text-decoration: underline;
  }
}
</style>
